//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DotError from "~/components/projects/DotError";


export default {
  name: 'ForgeImage',
  components: {
    DotError
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    secondSrc: {
      type: String,
      default: ''
    },
    secondLazySrc: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: ''
    },
    fit: {
      type: String,
      default: 'cover'
    },
    align: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Object,
      default: null
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isServer: false
    }
  },
  beforeCreate() {
    this.isServer = process.server
  },
  methods: {
    imageFit() {
      return {
        'cover': this.fit === 'cover',
        'fill': this.fit === 'fill',
        'contain': this.fit === 'contain',
        'none': this.fit === 'none',
        'scale-down': this.fit === 'scale-down',
      }
    },
    imageFitSSR() {
      return {
        'cover': this.fit === 'cover',
        'fill': this.fit === 'fill',
        'contain': this.fit === 'contain',
        'none': this.fit === 'none',
        'scale-down': this.fit === 'scale-down',
        'd-none': !this.isServer
      }
    },
    imageAlign() {
      return {
        'ma-auto': this.align === 'center',
      }
    },
    browseFile (id) {
      if (document.getElementById(id) != null)
        document.getElementById(id).click();
    },
    dropImage (e, index) {
      this.$emit('uploadImage', index, e.dataTransfer.files[0] instanceof File ? e.dataTransfer.files[0] : {})
    }
  }
}
