//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

export default {
  props: {
    deadline: {
      type: moment,
      default: '',
    },
    stop: {
      type: Boolean,
    },
    size: {
      type: String,
      default: 'normal',
    },
    completed: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: null,
      diff: 0,
      interval: null,
    }
  },
  computed: {
      seconds() {
        return Math.trunc(this.diff) % 60
      },

      minutes() {
        return Math.trunc(this.diff / 60) % 60
      },

      hours() {
        return Math.trunc(this.diff / 60 / 60) % 24
      },

      days() {
        return Math.trunc(this.diff / 60 / 60 / 24)
      },
  },
  watch: {
    now(value) {
      this.diff = this.date - this.now
      if (this.diff <= 0 || this.stop) {
        this.diff = 0
        clearInterval(this.interval)
        this.completed()
      }
    },
  },
  mounted() {
    this.processTime()
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    processTime() {
      if (!this.deadline || !this.deadline.isValid()) {
        throw new Error("Missing props 'deadline'")
      }

      this.date = this.deadline.unix()

      this.interval = setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000)
      }, 1000)
    },
  },
}
