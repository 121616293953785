//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import FeaturedCountdown from '@/components/FeaturedCountdown'

export default {
  name: 'CuratedProjectCard',
  components: {
    FeaturedCountdown,
  },
  filters: {
    strippedContent (string) {
      return string.replace(/<\/?[^>]+>/ig, " ");
    }
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
    reload: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    formattedUsername(username) {
      if (username === '') return ''

      if (username.length > 20) {
        return (
          username.substring(0, 6) +
          '...' +
          username.substring(
            username.length - 6
          )
        )
      }
      return username
    },
    formatDate(date) {
      return moment(date)
    },
    footerBackground(status) {
      const { statuses } = this.$config
      return {
        'nfty-feature-project-footer': status === statuses.scheduled,
        black: status === statuses.ongoing,
        white: status === statuses.ended || status === statuses.sold,
        'black--text': status === statuses.ended || status === statuses.sold,
      }
    },
    projectStatus(project) {
      const { statuses } = this.$config
      if (project.status === statuses.scheduled) {
        return 'Starting in'
      } else if (
        project.status === statuses.ongoing &&
        project.end_at !== null
      ) {
        return 'Ending'
      } else if (project.status === statuses.ongoing) {
        return 'Ongoing'
      } else {
        return 'Now Trading'
      }
    },
    showCountdown(project) {
      const { statuses } = this.$config
      if (
        project.status === statuses.scheduled ||
        (project.status === statuses.ongoing && project.end_at !== null)
      ) {
        return true
      }
      return false
    },
    filter(card) {
      return card.category === this.category || this.category === 'All'
    },
    onIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in')
        }
      })
    },
  }
}
