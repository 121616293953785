//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DotError',
  props: {
    message: {
      type: String,
      default: '',
    }
  },
}
